import React from "react";
import styled, { css } from "styled-components";
import smoothscroll from "smoothscroll-polyfill";
import ResizeObserver from "resize-observer-polyfill";

import { hideScrollbars } from "../utils/style";

/**
 * A utility component for creating a element that can be scrolled within a
 * container without displaying a scrollbar for the content's overflow.
 * This utility component currently only supports horizontal scrolling.
 *  *
 * This component can have any number of children. If the total width of the
 * children exceeds the width/height of the parent, the component will be
 * scrollable.
 *
 * @param {Boolean} bleedLeft Whether content that overflows to the left of the
 * container should bleed to the left edge of the window.
 * @param {Boolean} bleedRight Whether content that overflows to the right of
 * the container should bleed to the right edge of the window.
 * @param {Boolean} disableScroll Whether the container should be scrollable.
 * If false, the container can still be moved programatically by the
 * scrollToElement and scrollToIndex functions.
 *
 * Note: For the bleedLeft and bleedRight properties to work as intended, any
 * container wrapping this component must not have the overflow: hidden
 * property.
 */

const OverflowContainer = styled.div`
  overflow-x: ${p => (p.disableScroll ? "hidden" : "scroll")};
  font-size: 0;

  ${p =>
    p.centered
      ? css`
          margin: auto;
        `
      : css`
          margin: 0 -${p.rightGutter}px 0 -${p.leftGutter}px;
          scroll-padding: 0 ${p.rightGutter}px 0 ${p.leftGutter}px;
        `}

  ${hideScrollbars}

  scroll-snap-type: x mandatory;
`;

const DraggableContent = styled.div`
  display: inline-flex;
  white-space: nowrap;

  ${p =>
    p.centered ? "" : `padding: 0 ${p.rightGutter}px 0 ${p.leftGutter}px`};

  & > * {
    flex-shrink: 0;
  }
`;

export default class ScrollableOverflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boundary: { left: 0, top: 0 },
      hasOverflow: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleScrollDebounce = this._handleScrollDebounce.bind(this);

    this.childRefs = [];
  }

  componentDidMount() {
    smoothscroll.polyfill();
    // Observe changes in size for the overflowRef and the contentRef. The
    // overflowRef can change in size when the width of the parent of this
    // component changes. The contentRef can change in size when the width
    // of the children changes (i.e. images are loaded).
    if (ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(this.contentRef);
      this.resizeObserver.observe(this.overflowRef);
    }

    this.overflowRef.addEventListener("scroll", this.handleScrollDebounce);

    this.handleResize();
  }

  componentWillUnmount() {
    this.resizeObserver && this.resizeObserver.unobserve(this.contentRef);
    this.resizeObserver && this.resizeObserver.unobserve(this.overflowRef);

    this.overflowRef.removeEventListener("scroll", this.handleScrollDebounce);
    this.scrollDeounce && clearTimeout(this.scrollDeounce);
  }

  _getLeftGutter() {
    if (!this.props.bleedLeft) return 0;
    return this.props.gutterWidth || 1000;
  }

  _getRightGutter() {
    if (!this.props.bleedRight) return 0;
    return this.props.gutterWidth || 1000;
  }

  _handleScrollDebounce() {
    // Clear the existing timeout if one exists.
    this.scrollDeounce && clearTimeout(this.scrollDeounce);
    this.scrollDeounce = setTimeout(() => {
      this._handleScroll();
    }, 100);
  }

  _handleScroll() {
    if (this._isAtBoundaryStart()) {
      this.props.isAtStart && this.props.isAtStart();
    } else {
      this.props.isAfterStart && this.props.isAfterStart();
    }

    if (this._isAtBoundaryEnd()) {
      this.props.isAtEnd && this.props.isAtEnd();
    } else {
      this.props.isBeforeEnd && this.props.isBeforeEnd();
    }

    if (this.props.onActiveIndexChange) {
      const activeIndex = this._getActiveIndex();
      this.props.onActiveIndexChange(activeIndex);
    }
  }

  async handleResize() {
    await this._updateBoundary();

    const hasOverflow = this.state.boundary.left < 0;
    this.setState({
      hasOverflow,
    });

    this._handleScroll();

    if (this.props.onHasOverflow) {
      this.props.onHasOverflow(hasOverflow);
    }
  }

  scrollToIndex(i, position = "center", smooth = true) {
    const element = this.childRefs[i];
    if (!element) return;

    return this.scrollToElement(element, position, smooth);
  }

  scrollToElement(element, position = "center", smooth = true) {
    const elementOffset = element.offsetLeft - this.overflowRef.offsetLeft;
    const elementWidth = element.offsetWidth;
    const visibleWidth = this._getVisibleWidth();

    // The distance from the left of the overflowRef required to center the
    // current element horizontally. This does not include the element's margin.
    let offset = visibleWidth / 2 - elementWidth / 2;

    if (position === "left") {
      offset = this._getLeftGutter();
    } else if (position === "right") {
      offset = visibleWidth - this._getRightGutter();
    }

    let updatedX = elementOffset - offset;

    if (!this.overflowRef.scrollTo) {
      // This should only occur during testing.
      return;
    }

    this.overflowRef.scrollTo({
      left: updatedX,
      behavior: smooth ? "smooth" : "auto",
    });
  }

  _getLeftPosition() {
    return this.overflowRef ? this.overflowRef.scrollLeft * -1 : 0;
  }

  _getCenterPosition() {
    if (!this.overflowRef) return 0;
    const left = this._getLeftPosition();
    const width = this._getVisibleWidth();
    return width / 2 + left * -1;
  }

  _updateBoundary() {
    return new Promise(resolve => {
      let { left, top } = this._getBoundary();

      left = Math.ceil(left);
      top = Math.ceil(top);

      this.setState(
        {
          boundary: { left, top },
        },
        resolve,
      );
    });
  }

  _getBoundary() {
    const left = Math.min(this._getVisibleWidth() - this._getTotalWidth(), 0);
    return { left, top: 0 };
  }

  _getVisibleWidth() {
    return this.overflowRef ? this.overflowRef.offsetWidth : 0;
  }

  _getTotalWidth() {
    return this.overflowRef ? this.overflowRef.scrollWidth : 0;
  }

  _isAtBoundaryStart() {
    return this._getLeftPosition() >= 0;
  }

  _isAtBoundaryEnd() {
    return this._getLeftPosition() <= this.state.boundary.left;
  }

  _getActiveIndex() {
    if (!this.childRefs.length) {
      return -1;
    } else if (this._isAtBoundaryStart()) {
      return 0;
    } else if (this._isAtBoundaryEnd()) {
      return this.childRefs.length - 1;
    }

    const position = this._getCenterPosition();

    const index = this.childRefs.findIndex(ref => {
      const leftOffset = ref.offsetLeft - this.overflowRef.offsetLeft;
      const rightOffset = leftOffset + ref.offsetWidth;
      return position >= leftOffset && position < rightOffset;
    });
    return index;
  }

  render() {
    const { disableScroll, centered = true } = this.props;
    const { hasOverflow } = this.state;

    const leftGutter = this._getLeftGutter();
    const rightGutter = this._getRightGutter();

    let children = this.props.children;

    // If the passed through children props is an array, assign a ref to each
    // element.
    if (this.props.childRefs) {
      this.childRefs = this.props.childRefs;
    } else {
      if (children && children.length) {
        children = this.props.children.map((child, index) => {
          return React.cloneElement(child, {
            ref: r => {
              this.childRefs[index] = r;
            },
          });
        });
      }
    }

    return (
      <OverflowContainer
        centered={centered && !hasOverflow}
        leftGutter={leftGutter}
        rightGutter={rightGutter}
        disableScroll={disableScroll}
        ref={r => {
          if (this.props.testingRef) {
            // Should only be used for testing.
            this.overflowRef = this.props.testingRef;
          } else {
            this.overflowRef = r;
          }
        }}
      >
        <DraggableContent
          centered={centered && !hasOverflow}
          leftGutter={leftGutter}
          rightGutter={rightGutter}
          ref={r => {
            this.contentRef = r;
          }}
        >
          {children}
        </DraggableContent>
      </OverflowContainer>
    );
  }
}
